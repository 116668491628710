import imgs from "./ImageMap";

export const facultyDetails = [
  {
    imageUrl: imgs.f1,
    // role: "AI & Data Science Department",
    name: "Mrs. Sangeeta Oswal",
    links:{
      mail:"sangeeta.oswal@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.f2,
    // role: "Computer Department",
    name: "Mrs. Lifna C S",
    links:{
      mail:"lifna.cs@ves.ac.in"
    }
  },
];

export const top3MemberDetails = [
  {
    imageUrl: imgs.be1,
    role: "Chairperson",
    name: "Sakshi Shinde",
    links:{
      insta:"https://www.instagram.com/sakshirs_23/",
      linkedIn:"https://www.linkedin.com/in/sakshishinde5/",
      git:"https://github.com/sakshishinde23",
      mail:"2020.sakshi.shinde@ves.ac.in"
    }
  },

  {
    imageUrl: imgs.be2,
    role: "Sr. Treasurer",
    name: "Harsh Mohile",
    links:{
      insta:"https://www.instagram.com/harshm_/",
      linkedIn:"https://www.linkedin.com/in/harsh-mohile-8a5b111a9/",
      git:"https://github.com/HarshMohile7",
      mail:"2020.harsh.mohile@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.be3,
    role: "Sr. Secretary",
    name: "Anish Nair",
    links:{
      insta:"https://www.instagram.com/anish_3655/",
      linkedIn:"https://www.linkedin.com/in/anish-nair-255087220",
      git:"https://github.com/anishnair2703",
      mail:"2020.anish.nair@ves.ac.in"
    }
  },
];

export const beMemberDetails = [
  {
    imageUrl: imgs.be4,
    role: "Executive Committee",
    name: "Chaitanya Sondur",
    links:{
      insta:"https://www.instagram.com/chaits01._/",
      linkedIn:"https://www.linkedin.com/in/chaitanya-sondur-821141222/",
      git:"https://github.com/Chaitanya1402",
      mail:"2020.chaitanya.sondur@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.be5,
    role: "Executive Committee",
    name: "Mandar Ambre",
    links:{
      insta:"",
      linkedIn:"https://www.linkedin.com/in/mandar-ambre-8173a9221/",
      git:"https://github.com/AmbreMandar1812",
      mail:"2020.mandar.ambre@ves.ac.in "
    }
  },
  {
    imageUrl: imgs.be7,
    role: "Executive Committee",
    name: "Manav Jawrani",
    links:{
      insta:"https://www.instagram.com/justjawrani/",
      linkedIn:"http://www.linkedin.com/in/manavjawrani",
      git:"https://github.com/Manavjawrani",
      mail:"2020.manav.jawrani@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.be6,
    role: "Executive Committee",
    name: "Soham Bhole",
    links:{
      insta:"https://www.instagram.com/soham__bhole",
      linkedIn:"https://www.linkedin.com/in/soham-bhole-500605236",
      git:"",
      mail:"2020.soham.bhole@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.be8,
    role: "Executive Committee",
    name: "Varad Deshmukh",
    links:{
      insta:"https://www.instagram.com/varad1062/",
      linkedIn:"https://www.linkedin.com/in/varad-deshmukh-771a071ba",
      git:"https://github.com/Varaddd",
      mail:"2020.varad.deshmukh@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.be9,
    role: "Executive Committee",
    name: "Yash Sarang",
    links:{
      insta:"",
      linkedIn:"https://www.linkedin.com/in/yash-sarang/",
      git:"https://github.com/YashSarang",
      mail:"2020.yash.sarang@ves.ac.in"
    }
  },
];

export const top5MemberDetails = [
  {
    imageUrl: imgs.te1,
    role: "Co-Chairperson",
    name: "Gaurang Mapuskar",
    links:{
      insta:"https://www.instagram.com/invites/contact/?i=1d5l30j9ukdb5&utm_content=5vrihry",
      linkedIn:"https://www.linkedin.com/in/gaurang-mapuskar-25192b260",
      git:"https://github.com/GaurangMapuskar?tab=repositories",
      mail:"2021.gaurang.mapuskar@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te2,
    role: "Operations Secretary",
    name: "Amit Murkalmath",
    links:{
      insta:"https://www.instagram.com/murkalmxth.ammiiitttt/",
      linkedIn:"https://www.linkedin.com/in/amit-murkalmath-3a1229247",
      git:"https://github.com/LaFlameX07",
      mail:"2021.amit.murkalmath@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te3,
    role: "Managing Secretary",
    name: "Aradhya Ingle",
    links:{
      insta:"",
      linkedIn:"",
      git:"",
      mail:""
    }
  },
  {
    imageUrl: imgs.te4,
    role: "Jr. Treasurer",
    name: "Anurag Shirsekar",
    links:{
      insta:"https://instagram.com/_.anuragg94?igshid=NTA5ZTk1NTc=",
      linkedIn:"https://www.linkedin.com/in/anurag-shirsekar-b77192247",
      git:"https://github.com/ragg-143",
      mail:"2021.anurag.shirsekar@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te5,
    role: "Public Relations Head",
    name: "Sakshi Ganjewar",
    links:{
      insta:"https://instagram.com/ft.sakshhyyy_?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D",
      linkedIn:"https://www.linkedin.com/in/sakshi-ganjewar-878785249",
      git:"",
      mail:"2021.sakshi.ganjewar@ves.ac.in"
    }
  },
];

export const teMemberDetails = [
  {
    imageUrl: imgs.te6,
    role: "Sr. Public Relations Officer",
    name: "Manali Patil",
    links:{
      insta:"https://instagram.com/_manali.20_?igshid=ZmZhODViOGI=",
      linkedIn:"https://www.linkedin.com/in/manali-patil-383bb3250",
      git:"https://github.com/manali120",
      mail:"2021.manali.patil@ves.ac.in "
    }
  },
  {
    imageUrl: imgs.te7,
    role: "Sr. Public Relations Officer",
    name: "Disha Tardeja",
    links:{
      insta:"https://instagram.com/disha6113?igshid=MmIzYWVlNDQ5Yg==",
      linkedIn:"",
      git:"",
      mail:"2021.disha.tardeja@ves.ác.in "
    }
  },
  {
    imageUrl: imgs.te8,
    role: "Sr. Public Relations Officer",
    name: "Om Gaikwad",
    links:{
      insta:"https://instagram.com/omgaikwad22_?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D",
      linkedIn:"https://www.linkedin.com/in/omgaikwadb221",
      git:"",
      mail:"2021.om.gaikwad@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te9,
    role: "Sr. Public Relations Officer",
    name: "Sejal Datir",
    links:{
      insta:"https://www.instagram.com/sejjal_._/",
      linkedIn:"https://www.linkedin.com/in/sejal-datir-897576247/",
      git:"https://github.com/sejalapeno",
      mail:"2021.sejal.datir@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te23,
    role: "Sr. Public Relations Officer",
    name: "Jesica Bijju",
    links:{
      insta:"",
      linkedIn:"https://www.linkedin.com/in/jesica-annie-bijju-689696269/",
      git:"https://github.com/jesshush",
      mail:"2021.jesica.bijju@ves.ac.in "
    }
  },
  {
    imageUrl: imgs.te21,
    role: "Sr. Editor",
    name: "Parul Pritamwani",
    links:{
      insta:"https://instagram.com/parulpritamwani?igshid=NmQ2ZmYxZjA=",
      linkedIn:"https://www.linkedin.com/in/parul-pritamwani-57316b264",
      git:"",
      mail:"d2021.parul.pritamwani@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te22,
    role: "Sr. Editor",
    name: "Mayuresh Pednekar",
    links:{
      insta:"https://instagram.com/_mayureshp?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D",
      linkedIn:"http://www.linkedin.com/in/mayuresh-pednekar-101694273",
      git:"https://github.com/MayureshPednekar",
      mail:"2021.mayuresh.pednekar@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te10,
    role: "Sr. Technical Officer",
    name: "Niyati Gaonkar",
    links:{
      insta:"https://instagram.com/niyatig26?igshid=NTA5ZTk1NTc=",
      linkedIn:"https://www.linkedin.com/in/niyati-gaonkar-ba0942260",
      git:"https://github.com/Niyati2609",
      mail:"2021.niyati.gaonkar@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te11,
    role: "Sr. Technical Officer",
    name: "Simran Ahuja",
    links:{
      insta:"https://instagram.com/simran.ahuja03?igshid=NTc4MTIwNjQ2YQ==",
      linkedIn:"https://in.linkedin.com/in/simran-ahuja-a1129a213",
      git:"https://github.com/SimranAhuja00",
      mail:"2021.simran.ahuja@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te12,
    role: "Sr. Technical Officer",
    name: "Ashish Patil",
    links:{
      insta:"https://www.instagram.com/ashtrobuff/",
      linkedIn:"https://in.linkedin.com/in/ashish-patil-60371b258",
      git:"https://github.com/Ashtrobuff",
      mail:"2021.ashish.patil@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te13,
    role: "Sr. Web Editor",
    name: "Dhruva Chaudhari",
    links:{
      insta:"https://www.instagram.com/dhruvaa_6/?next=%2F",
      linkedIn:"https://www.linkedin.com/in/dhruva-chaudhari-021223228/",
      git:"https://github.com/dhruvachaudhari",
      mail:"2021.dhruva.chaudhari@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te14,
    role: "Sr. Web Editor",
    name: "Shivani Nikam",
    links:{
      insta:"https://instagram.com/_.shivaaniii._?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D",
      linkedIn:"",
      git:"https://github.com/ShivaniNikam1",
      mail:"2021.shivani.nikam@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te15,
    role: "Sr. Web Editor",
    name: "Sumeet Singh",
    links:{
      insta:"https://www.instagram.com/ig_siuumeet/",
      linkedIn:"https://www.linkedin.com/in/sumeet-singh-358b8a1b8/",
      git:"https://github.com/sumeet05xcaliber",
      mail:"2021.sumeet.singh@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te16,
    role: "Sr. Operations Officer",
    name: "Madhura Gaval",
    links:{
      insta:"https://instagram.com/m.4.d.h.u.r.7?igshid=NTA5ZTk1NTc=",
      linkedIn:"http://www.linkedin.com/in/madhura-gaval-a803b6264",
      git:"https://github.com/Madhura473",
      mail:"2021.madhura.gaval@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te17,
    role: "Sr. Operations Officer",
    name: "Dinesh Ubrani",
    links:{
      insta:"https://www.instagram.com/dinesh_ubrani11/",
      linkedIn:"https://www.linkedin.com/in/dinesh-ubrani-612348257/",
      git:"https://github.com/Dinesh1109",
      mail:"2021.dinesh.ubrani@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te18,
    role: "Sr. Operations Officer",
    name: "Ameya Angne",
    links:{
      insta:"https://instagram.com/ameyaangne?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D",
      linkedIn:"https://www.linkedin.com/in/ameya-angne-02a728199/",
      git:"https://github.com/AmeyaMAngne",
      mail:"2021.ameya.angne@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te20,
    role: "Sr. Operations Officer",
    name: "Gomati Iyer",
    links:{
      insta:"https://www.instagram.com/gomatii_2624/?igshid=MzNlNGNkZWQ4Mg%3D%3D",
      linkedIn:"https://www.linkedin.com/in/gomati-iyer-975479243/",
      git:"https://github.com/Gomatii",
      mail:"2021.gomati.iyer@ves.ac.in "
    }
  },
  {
    imageUrl: imgs.te24,
    role: "Sr. Operations Officer",
    name: "Siddhi Bhogale",
    links:{
      insta:"https://instagram.com/bhogale_siddhi28?igshid=NGVhN2U2NjQ0Yg==",
      linkedIn:"www.linkedin.com/in/siddhi-bhogale-8620ba25b",
      git:"https://github.com/bhogalesiddhi",
      mail:"2021.siddhi.bhogale@ves.ac.in"
    }
  },
  {
    imageUrl: imgs.te19,
    role: "Sr. Operations Officer",
    name: "Pratik Wagharalkar",
    links:{
      insta:"https://instagram.com/pratik_wagharalkar?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D",
      linkedIn:"https://www.linkedin.com/in/pratik-wagharalkar-8a4ab9273",
      git:"https://github.com/Prathamesh-Thakur",
      mail:"2021.pratik.wagharalkar@ves.ac.in "
    }
  },
];

export const seMemberDetails = [
  {
    imageUrl:imgs.se1,
    role: "Jr. Operations Officer",
    name: "Shreyash Bhute",
    links:{
      insta:"https://instagram.com/shreyash_0904?igshid=NGVhN2U2NjQ0Yg==",
      linkedIn:"",
      git:"",
      mail:"2022.shreyash.bhute@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se2,
    role: "Jr. Operations Officer",
    name: "Arya Madhavi",
    links:{
      insta:"https://instagram.com/aryamadhavi03?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"https://www.linkedin.com/in/arya-madhavi-596782294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/aryamadhavi03",
      mail:"2022.arya.madhavi@ves.ac.in "
    }
  },
  {
    imageUrl:imgs.se3,
    role: "Jr. Operations Officer",
    name: "Shubham Jha",
    links:{
      insta:"https://instagram.com/bl42e_1331?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"",
      git:"",
      mail:"2021.shubham.jha@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se4,
    role: "Jr. Operations Officer",
    name: "Shravani Patil",
    links:{
      insta:"https://instagram.com/shravani.patil_27?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"https://instagram.com/shravani.patil_27?igshid=OGQ5ZDc2ODk2ZA==",
      git:"https://github.com/ShravaniAnilPatil",
      mail:"2022.shravani.patil@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se5,
    role: "Jr. Operations Officer",
    name: "Sarthak Harade",
    links:{
      insta:"https://www.instagram.com/__sarthakkk_16/",
      linkedIn:"https://www.linkedin.com/in/sarthak-undefined-17764a294",
      git:"https://github.com/Sarthaksl",
      mail:"2022.sarthak.harade@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se6,
    role: "Jr. Operations Officer",
    name: "T.Jennifer Cheriyan",
    links:{
      insta:"https://instagram.com/jennifer.cheriyan_?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D",
      linkedIn:"https://www.linkedin.com/in/jennifer-cheriyan-437b7b286/",
      git:"https://github.com/Jennifercheriyan09",
      mail:"2022.jennifer.thozhuvathinkal@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se7,
    role: "Jr. Operations Officer",
    name: "Nimish Chug",
    links:{
      insta:"https://instagram.com/nimishchug3?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"https://www.linkedin.com/in/nimish-chug-48459726b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/nimishchug3",
      mail:"2022.nimish.chug@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se8,
    role: "Jr. Operations Officer",
    name: "Nikunj Pal",
    links:{
      insta:"https://instagram.com/nikunjpal20?igshid=NzZlODBkYWE4Ng==",
      linkedIn:"http://linkedin.com/in/nikunj-pal-40b54b294",
      git:"https://github.com/nikunjpal20",
      mail:"2022.nikunj.pal@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se9,
    role: "Jr. Public Relations Officer",
    name: "Surel Sanap",
    links:{
      insta:"https://instagram.com/surelsanap?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"",
      git:"",
      mail:"2022.surel.sanap@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se10,
    role: "Jr. Public Relations Officer",
    name: "Simran Godhwani",
    links:{
      insta:"https://instagram.com/simran_godhwani09?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"https://www.linkedin.com/in/simran-godhwani-06093b25b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/simrangodhwani",
      mail:"2022.simran.godhwani@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se11,
    role: "Jr. Public Relations Officer",
    name: "Taniya Vallecha",
    links:{
      insta:"https://instagram.com/taniya_vallecha?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"https://www.linkedin.com/in/taniya-vallecha-63b93b25b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/TaniyaVallecha",
      mail:"2022.taniya.vallecha@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se12,
    role: "Jr. Public Relations Officer",
    name: "Rohit Makattil ",
    links:{
      insta:"https://instagram.com/makattil.rohit?igshid=MzRlODBiNWFlZA==",
      linkedIn:"https://www.linkedin.com/in/rohit-makattil-30464b294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/RohitMakattil",
      mail:"2022.rohit.makattil@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se13,
    role: "Jr. Public Relations Officer",
    name: "Prathamesh Palve",
    links:{
      insta:"https://instagram.com/prathmzz?igshid=YTQwZjQ0NmI0OA==",
      linkedIn:"https://www.linkedin.com/in/prathamesh-palve-093066284?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/prathmzz",
      mail:"2022.prathamesh.palve@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se14,
    role: "Jr. Public Relations Officer",
    name: "Anushka Shahane ",
    links:{
      insta:"https://instagram.com/anushkashahane_03?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"",
      git:"",
      mail:"2022.anushka.shahane@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se15,
    role: "Jr. Public Relations Officer",
    name: "Shravani Sawant",
    links:{
      insta:"https://instagram.com/shravanisawant_4?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"https://www.linkedin.com/in/shravani-shrikrishna-sawant-6aa234253?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/Shshnyy",
      mail:"2022.shravani.sawant@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se16,
    role: "Jr. Web Editor",
    name: "Aditya Kumar",
    links:{
      insta:"https://www.instagram.com/adityask007/",
      linkedIn:"https://www.linkedin.com/in/aditya-sampath-kumar-abab94280?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/Adityacodesinpython",
      mail:"2022.aditya.sampathkumar@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se17,
    role: "Jr. Web Editor",
    name: "Karan Bhatia",
    links:{
      insta:"https://www.instagram.com/karan_bhatia857/",
      linkedIn:"www.linkedin.com/in/karan-bhatia-b4039b250",
      git:"https://github.com/karanbhatia26",
      mail:"2022.karan.bhatia@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se18,
    role: "Jr. Web Editor",
    name: "Abhijeet Pandey",
    links:{
      insta:"",
      linkedIn:"",
      git:"",
      mail:"2022.abhijeet.pandey@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se19,
    role: "Jr. Web Editor",
    name: "Ayush Bohra",
    links:{
      insta:"https://instagram.com/ayush_bohra_7?igshid=NzZlODBkYWE4Ng==",
      linkedIn:"https://www.linkedin.com/in/ayush-bohra7",
      git:"https://github.com/CodeFingers809",
      mail:"2022.ayush.bohra@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se20,
    role: "Jr. Web Editor",
    name: "Prajjwal Pandey",
    links:{
      insta:"https://instagram.com/prajjwal.pep?igshid=NzZlODBkYWE4Ng==",
      linkedIn:"https://www.linkedin.com/in/prajjwal-pandey-a82b44264?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/Prajjwal-pep",
      mail:"2022.prajjwal.pandey@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se21,
    role: "Jr. Technical Officer",
    name: "Sami Thakur",
    links:{
      insta:"https://www.instagram.com/sami_thakur_/?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D",
      linkedIn:"https://www.linkedin.com/in/sami-thakur-6072b8256/",
      git:"https://github.com/SAMI-THAKUR",
      mail:"2022.sami.thakur@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se22,
    role: "Jr. Technical Officer",
    name: "Sagar Rajak",
    links:{
      insta:"https://instagram.com/sagarrajak003?utm_source=qr&igshid=YzU1NGVlODEzOA==",
      linkedIn:"https://www.linkedin.com/in/sagar-rajak-933788257?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/sagarrajak245e",
      mail:"2022.sagar.rajak@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se23,
    role: "Jr. Technical Officer",
    name: "Aadil Shah",
    links:{
      insta:"https://instagram.com/adilns786?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"https://www.linkedin.com/in/aadil-shah-9b9b2828a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/AadilShah786",
      mail:"2022.aadil.shah@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se24,
    role: "Jr. Technical Officer",
    name: "Garv Chawla",
    links:{
      insta:"https://www.instagram.com/garv.css/",
      linkedIn:"http://www.linkedin.com/in/garv-chawla7",
      git:"https://github.com/Garvc",
      mail:"2022.garv.chawla@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se25,
    role: "Jr. Technical Officer",
    name: "Vedika Walhe",
    links:{
      insta:"https://instagram.com/vedikawalhe?igshid=NGVhN2U2NjQ0Yg==",
      linkedIn:"https://www.linkedin.com/in/vedika-walhe-1ab1b2287",
      git:"https://github.com/Vedika-Walhe",
      mail:"2022.vedika.walhe@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se26,
    role: "Jr. Editor",
    name: "Anish Padhye",
    links:{
      insta:"https://instagram.com/anish_padhye?igshid=MzNlNGNkZWQ4Mg==",
      linkedIn:"",
      git:"",
      mail:"2022.anish.padhye@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se27,
    role: "Jr. Editor",
    name: "Simran Karamchandani",
    links:{
      insta:"https://instagram.com/simran.b.k?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"https://www.linkedin.com/in/simran-karamchandani-32030a2a3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"https://github.com/simranbk",
      mail:"2022.simran.karamchandani@ves.ac.in"
    }
  },
  {
    imageUrl:imgs.se28,
    role: "Jr. Editor",
    name: "Aryan Singh",
    links:{
      insta:"https://instagram.com/aryan_05singh?igshid=OGQ5ZDc2ODk2ZA==",
      linkedIn:"https://www.linkedin.com/in/aryan-singh-215006203?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      git:"",
      mail:"2022.aryan.singh@ves.ac.in"
    }
  },
];
