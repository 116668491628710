import { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NavBar, Footer, Loader, MiniLoader } from './components';
import { useDarkMode } from './darkMode/useDarkMode';
import './styles/App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Council = lazy(() => import("./pages/Council"));
const Work = lazy(() => import("./pages/Work"));
const Contact = lazy(() => import("./pages/Contact"));
const NotFound = lazy(() => import("./pages/NotFound"));

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const [theme, themeToggler, mountedComponent] = useDarkMode();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 6000);
    window.localStorage.clear();
  }, []);

  AOS.init();
  
  return (
    <Router>
      {
        isLoading === true? ( <Loader /> ) : (
          <div className = "App">
            <NavBar theme={theme} themeToggler={themeToggler} mountedComponent={mountedComponent} />
            <div className="content" style={{minHeight:"calc(100vh - 240px)"}}>
              <Suspense fallback={<MiniLoader />}>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  {/* <Route exact path="/home">
                    <Home />
                  </Route> */}
                  <Route exact path="/about">
                    <About />
                  </Route>
                  <Route exact path="/council">
                    <Council/>
                  </Route>
                  <Route exact path="/work">
                    <Work />
                  </Route>
                  <Route exact path="/contact">
                    <Contact />
                  </Route>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </Suspense>
            </div>
            <Footer />
          </div >
        )
      }
    </Router>
  );
}

export default App;
