export const lightTheme = {
    body: '#bce3f0',
    text: '#333',
    toggleBorder: '#FFF',
    background: '#333',
    styledCard: '#d4f5f5',
    styledCouncilCard: '#eeffee',
    carouselCardBody: 'rgb(41, 93, 141)',
    carouselCardText: '#f0ffff',
    thumb: 'rgb(41, 93, 141)',
    counter: '#add8e6',
    counterItem: '#d4f5f5',
    counterText: '#333',
}

export const darkTheme = {
    body: 'rgb(37, 36, 36)',
    text: '#bce3f0',
    toggleBorder: '#6B8096',
    background: '#999',
    styledCard: '#333',
    styledCouncilCard: '#333',
    carouselCardBody: 'rgb(41, 93, 141)',
    carouselCardText: '#f0ffff',
    thumb: '#bce3f0',
    counter: '#333',
    counterItem: '#555',
    counterText: '#bce3f0',
}
