import { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../darkMode/GlobalStylesComponent';
import { lightTheme, darkTheme } from '../../darkMode/Theme';
import Toggle from '../../darkMode/Toggler';
import { motion } from 'framer-motion';

const NavBar = ({ theme, themeToggler, mountedComponent }) => {
    const themeMode = theme === 'light' ? lightTheme : darkTheme;

    const [active, setActive] = useState('home');

    if (!mountedComponent) return <div />
    return (
        <ThemeProvider theme={themeMode}>
            <GlobalStyles />
            <Navbar collapseOnSelect expand="lg" className="px-md-5">
                <LinkContainer exact to="/" eventkey="home" onClick={(selectedKey) => setActive(selectedKey)}>
                    <Navbar.Brand>
                        <motion.img
                            src="/csi_logo.webp"
                            width="50"
                            height="50"
                            className="d-inline-block align-top"
                            alt="CSI-VESIT Logo"
                            animate={{ scale: [0, 0.5, 0.8, 1.15], rotate: [0, 360] }}
                        />
                        <span style={{ color: "floralwhite", position: "relative", top: "10px", left: "15px", fontWeight: "500" }}>CSI - VESIT</span>
                    </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto" activeKey={active} onSelect={(selectedKey) => setActive(selectedKey)}>
                        <LinkContainer exact to="/">
                            <Nav.Link eventkey="home" className={active==='home'?"ml-3 mr-3 nav-active":"ml-3 mr-3"}>Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer exact to="/about">
                            <Nav.Link eventkey="about" className={active==='about'?"ml-3 mr-3 nav-active":"ml-3 mr-3"}>About Us</Nav.Link>
                        </LinkContainer>
                        <LinkContainer exact to="/council">
                            <Nav.Link eventkey="team" className={active==='team'?"ml-3 mr-3 nav-active":"ml-3 mr-3"}>Our Team</Nav.Link>
                        </LinkContainer>
                        <LinkContainer exact to='/work'>
                            <Nav.Link eventkey="work" className={active==='work'?"ml-3 mr-3 nav-active":"ml-3 mr-3"}>Our Work</Nav.Link>
                        </LinkContainer>
                        <LinkContainer exact to="/contact">
                            <Nav.Link eventkey="contact" className={active==='contact'?"ml-3 mr-3 nav-active":"ml-3 mr-3"}>Get In Touch</Nav.Link>
                        </LinkContainer>
                    </Nav>
                    <Toggle theme={theme} toggleTheme={themeToggler} className="dark-mode-toggler" />
                </Navbar.Collapse>
            </Navbar>
        </ThemeProvider>
    );
}

export default NavBar;
