import D20B from "../../assets/about/D20B.webp";
import D17C from "../../assets/about/D17C.webp";
import D17A from "../../assets/about/D17A.webp";
import D12A_2 from "../../assets/about/D12A_2.webp";
import D12B_2 from "../../assets/about/D12B_2.webp";
import D15B_2 from "../../assets/about/D15B_2.webp";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Testimonials = () => (
      <>
        <div className="d-none d-lg-block" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="1500">
          <Carousel
            showArrows={false}
            infiniteLoop={true}
            showThumbs={true}
            showStatus={false}
            autoPlay={true}
            interval={6100}
            data-aos="fade-left"
          >
            

            <div className="testimonialsBody">
              <img src={D17A} alt="Student1" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Radhika Katiyara</h3>
                <h4 className="darkFont">-D17A</h4>
                <p className="testimonials-p darkFont">
                  CSI-VESIT workshops helped me a lot to improve my coding skills. All sessions were amazing and understandable which were useful in creating projects! I thank each and everyone of this council for conducting such great sessions in such a short span of time!
                </p>
              </div>
            </div>

            <div className="testimonialsBody">
              <img src={D20B} alt="Student2" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Raghuttam Parvatikar</h3>
                <h4 className="darkFont">-D20B</h4>
                <p className="testimonials-p darkFont" style={{ paddingTop: '20px' }}>
                  CSI has introduced me to some important technical domains where it could've taken me months to discover it by myself. I'm grateful to each and every individual in the CSI council who has helped me.
                </p>
              </div>
            </div>

            <div className="testimonialsBody">
              <img src={D17C} alt="Student3" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Sneha Karthik</h3>
                <h4 className="darkFont">-D17C</h4>
                <p className="testimonials-p darkFont">
                  CSI-VESIT workshops definitely helped me hone my skills. There were a lot of concepts that I was able re-learn at these short workshops as they taught everything that was beneficial. The instructors made sure that we learned the most in the shortest span of time.
                </p>
              </div>
            </div>

            <div className="testimonialsBody">
              <img src={D12A_2} alt="Student4" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Isha Desai</h3>
                <h4 className="darkFont">-D12A</h4>
                <p className="testimonials-p darkFont">
                  I am grateful to the CSI-VESIT council for organizing such informative and impactful workshops in a short period. The sessions were incredibly well-structured and easy to comprehend, which significantly boosted my coding abilities. I truly appreciate the efforts put in by everyone involved in making these workshops a success.                
                </p>
              </div>
            </div>

            <div className="testimonialsBody">
              <img src={D12B_2} alt="Student5" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Gautam Wadhwani</h3>
                <h4 className="darkFont">-D12B</h4>
                <p className="testimonials-p darkFont">
                  Learning the interesting frameworks of web Development such as Laravel, PHP,Django,etc. It was an amazing experience while attending those insightful workshops. The CSI team was very helpful for all the members.
                </p>
              </div>
            </div>

            <div className="testimonialsBody">
              <img src={D15B_2} alt="Student6" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Shree Samal</h3>
                <h4 className="darkFont">-D15B</h4>
                <p className="testimonials-p darkFont">
                  I have personally witnessed the positive impact of your initiatives, from organizing coding workshops and hackathons to providing invaluable resources and mentorship for students interested in pursuing a career in technology. Your commitment to fostering a love for coding and technology among students is truly inspiring. I know that I am not alone in my appreciation for all that you do.
                </p>
              </div>
            </div>
          </Carousel>
        </div>
        <div className="d-lg-none">
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={6100}
            data-aos="fade-left"
          >

            <div className="testimonialsBody">
              <img src={D17A} alt="Student1" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Radhika Katiyara</h3>
                <h4 className="darkFont">-D17A</h4>
                <p className="testimonials-p darkFont">
                  CSI-VESIT workshops helped me a lot to improve my coding skills. All sessions were amazing and understandable which were useful in creating projects! I thank each and everyone of this council for conducting such great sessions in such a short span of time!
                </p>
              </div>
            </div>

            <div className="testimonialsBody">
              <img src={D20B} alt="Student2" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Raghuttam Parvatikar</h3>
                <h4 className="darkFont">-D20B</h4>
                <p className="testimonials-p darkFont" style={{ paddingTop: '20px' }}>
                  CSI has introduced me to some important technical domains where it could've taken me months to discover it by myself. I'm grateful to each and every individual in the CSI council who has helped me.
                </p>
              </div>
            </div>

            <div className="testimonialsBody">
              <img src={D17C} alt="Student3" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Sneha Karthik</h3>
                <h4 className="darkFont">-D17C</h4>
                <p className="testimonials-p darkFont">
                  CSI vesit workshops definitely helped me hone my skills. There were a lot of concepts that I was able re learn at these short workshops as they taught everything that was beneficial. The instructors made sure that we learned the most in the shortest span of time .
                </p>
              </div>
            </div>

            <div className="testimonialsBody">
              <img src={D12A_2} alt="Student4" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Isha Desai</h3>
                <h4 className="darkFont">-D12A</h4>
                <p className="testimonials-p darkFont">
                  I am grateful to the CSI-VESIT council for organizing such informative and impactful workshops in a short period. The sessions were incredibly well-structured and easy to comprehend, which significantly boosted my coding abilities. I truly appreciate the efforts put in by everyone involved in making these workshops a success.                
                </p>
              </div>
            </div>

            <div className="testimonialsBody">
              <img src={D12B_2} alt="Student5" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Gautam Wadhwani</h3>
                <h4 className="darkFont">-D12B</h4>
                <p className="testimonials-p darkFont">
                  Learning the interesting frameworks of web Development such as Laravel, PHP,Django,etc. It was an amazing experience while attending those insightful workshops. The CSI team was very helpful for all the members.
                </p>
              </div>
            </div>

            <div className="testimonialsBody">
              <img src={D15B_2} alt="Student6" />
              <div className="myCarousel">
                <h3 className="studentName darkFont">Shree Samal</h3>
                <h4 className="darkFont">-D15B</h4>
                <p className="testimonials-p darkFont">
                  I have personally witnessed the positive impact of your initiatives, from organizing coding workshops and hackathons to providing invaluable resources and mentorship for students interested in pursuing a career in technology. Your commitment to fostering a love for coding and technology among students is truly inspiring. I know that I am not alone in my appreciation for all that you do.
                </p>
              </div>
            </div>
          </Carousel>
        </div>
      </>
    );

export default Testimonials;
