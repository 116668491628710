import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';

const ContactCarouselLarge = () => {
    return (
        <div className="d-none d-md-block">
            <Container className="about-content shadow" data-aos="fade-up" data-aos-duration="1000" style={{marginTop: "70px"}}>
                <h1 className="heading pt-5">Hit Us Up! 🤙</h1>
                <Row className="mb-5 pb-5">
                    <Col xs={{ span: 12 }} md={{ span: 8, offset: 2 }}>
                        <Card className="my-md-2 my-lg-5 contact-carousel contact-form-card">
                            <Card.Header>
                                <Row><h3 className="mx-auto">Contact Details</h3></Row>
                            </Card.Header>
                            <Card.Body>
                                <Carousel
                                    controls={false}
                                    interval={4000}
                                    className="pb-lg-5"
                                >
                                    <Carousel.Item>
                                        <h4 className="ml-0 contact-list-header">Got any Queries? </h4>
                                        <div className="mx-auto">
                                            <ul className='contact-list mt-4'>
                                                <li className="mb-3"><strong>Sakshi Shinde</strong> (<i>Chairperson</i>): +91 82758 68278</li>
                                                <li><strong>Sakshi Ganjewar</strong> (<i>Public Relations Head</i>): +91 92841 33351</li>
                                            </ul>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <h4 className="ml-0 contact-list-header">Memberships Info? </h4>
                                        <div className="mx-auto">
                                            <ul className='contact-list'>
                                                <li className="mt-4 mb-3"><strong>Harsh Mohile</strong> (<i>Sr. Treasurer</i>): +91 76780 79156</li>
                                                <li><strong>Anurag Shirsekar</strong> (<i>Jr. Treasurer</i>): +91 82915 29650</li>
                                            </ul>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <h4 className="ml-0 contact-list-header">Technical Trouble? </h4>
                                        <div className="mx-auto">
                                            <ul className='contact-list'>
                                                <li className="mt-4 mb-3"><strong>Anish Nair</strong> (<i>Sr. Secretary</i>): +91 86574 17380</li>
                                                <li><strong>Gaurang Mapuskar</strong> (<i>Co-Chairperson</i>): +91 99697 80696</li>
                                            </ul>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <h4 className="ml-0 contact-list-header">Any other Assistance? </h4>
                                        <div className="mx-auto">
                                            <ul className='contact-list'>
                                                <li className="mt-4 mb-3"><strong>Amit Murkalmath</strong> (<i>Operations Secretary</i>): +91 86929 22529</li>
                                                <li><strong>Aradhya Ingle</strong> (<i>Managing Secretary</i>): +91 94049 65065</li>
                                            </ul>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ContactCarouselLarge;