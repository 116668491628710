const imgs = {
    f1: require('../../assets/council/Mrs. Sangeeta_Oswal.webp').default,
    f2: require('../../assets/council/Mrs. Lifna_C_S.webp').default,

    be1:  require('../../assets/council/BE/Sakshi_Shinde.webp').default,
    be2:  require('../../assets/council/BE/Harsh_Mohile.webp').default,
    be3:  require('../../assets/council/BE/Anish_Nair.webp').default,

    be4:  require('../../assets/council/BE/Chaitanya_Sondur.webp').default,
    be5:  require('../../assets/council/BE/Mandar_Ambre.webp').default,
    be7:  require('../../assets/council/BE/Manav_Jawrani.webp').default,
    be6:  require('../../assets/council/BE/Soham_Bhole.webp').default,
    be8:  require('../../assets/council/BE/Varad_Deshmukh.webp').default,
    be9:  require('../../assets/council/BE/Yash_Sarang.webp').default,

    te1: require('../../assets/council/TE/Gaurang_Mapuskar.webp').default,
    te2: require('../../assets/council/TE/Amit_Murkalmath.webp').default,
    te3: require('../../assets/council/TE/Aradhya_Ingale.webp').default,
    te4: require('../../assets/council/TE/Anurag_Shirsekar.webp').default,
    te5: require('../../assets/council/TE/Sakshi_Ganjewar.webp').default,

    te6: require('../../assets/council/TE/Manali_Patil.webp').default,
    te7: require('../../assets/council/TE/Disha_Tardeja.webp').default,
    te8: require('../../assets/council/TE/Om_Gaikwad.webp').default,
    te9: require('../../assets/council/TE/Sejal_Datir.webp').default,
    te10: require('../../assets/council/TE/Niyati_Gaonkar.webp').default,
    te11: require('../../assets/council/TE/Simran_Ahuja.webp').default,
    te12: require('../../assets/council/TE/Ashish_Patil.webp').default,
    te13: require('../../assets/council/TE/Dhruva_Chaudhari.webp').default,
    te14: require('../../assets/council/TE/Shivani_Nikam.webp').default,
    te15: require('../../assets/council/TE/Sumeet_Singh.webp').default,
    te16: require('../../assets/council/TE/Madhura_Gaval.webp').default,
    te17: require('../../assets/council/TE/Dinesh_Ubrani.webp').default,
    te18: require('../../assets/council/TE/Ameya_Angne.webp').default,
    te19: require('../../assets/council/TE/Pratik_Wagharalkar.webp').default,
    te20: require('../../assets/council/TE/Gomati_Iyer.webp').default,
    te21: require('../../assets/council/TE/Parul_Pritamwani.webp').default,
    te22: require('../../assets/council/TE/Mayuresh_Pednekar.webp').default,
    te23: require('../../assets/council/TE/Jesica_Bijju.webp').default,
    te24: require('../../assets/council/TE/Siddhi_Bhogale.webp').default,


    se1: require('../../assets/council/SE/Shreyash_Bhute.webp').default,
    se2: require('../../assets/council/SE/Arya_Madhavi.webp').default,
    se3: require('../../assets/council/SE/Shubham_Jha.webp').default,
    se4: require('../../assets/council/SE/Shravani_Patil.webp').default,
    se5: require('../../assets/council/SE/Sarthak_Harade.webp').default,
    se6: require('../../assets/council/SE/Jennifer_Cheriyan.webp').default,
    se7: require('../../assets/council/SE/Nimish_Chug.webp').default,
    se8: require('../../assets/council/SE/Nikunj_Pal.webp').default,

    se9: require('../../assets/council/SE/Surel_Sanap.webp').default,
    se10: require('../../assets/council/SE/Simran_Godhwani.webp').default,
    se11: require('../../assets/council/SE/Taniya_Vallecha.webp').default,
    se12: require('../../assets/council/SE/Rohit_Makattil.webp').default,
    se13: require('../../assets/council/SE/Prathamesh_Palve.webp').default,
    se14: require('../../assets/council/SE/Anushka_Shahane.webp').default,
    se15: require('../../assets/council/SE/Shravani_Sawant.webp').default,

    se16: require('../../assets/council/SE/Aditya_Kumar.webp').default,
    se17: require('../../assets/council/SE/Karan_Bhatia.webp').default,
    se18: require('../../assets/council/SE/Abhijeet_Pandey.webp').default,
    se19: require('../../assets/council/SE/Ayush_Bohra.webp').default,
    se20: require('../../assets/council/SE/Prajjwal_Pandey.webp').default,

    se21: require('../../assets/council/SE/Sami_Thakur.webp').default,
    se22: require('../../assets/council/SE/Sagar_Rajak.webp').default,
    se23: require('../../assets/council/SE/Aadil_Shah.webp').default,
    se24: require('../../assets/council/SE/Garv_Chawla.webp').default,
    se25: require('../../assets/council/SE/Vedika_Walhe.webp').default,

    se26: require('../../assets/council/SE/Anish_Padhye.webp').default,
    se27: require('../../assets/council/SE/Simran_Karamchandani.webp').default,
    se28: require('../../assets/council/SE/Aryan_Singh.webp').default,
}

export default imgs;