import "./councilCard.css";
import { Col } from "react-bootstrap";
import { FaGithub, FaInstagram, FaLinkedinIn, FaEnvelope } from 'react-icons/fa';

const CouncilCard = (props) => {
  return (
    <Col md={4} sm={6} xs={12} className="mb-5">
      {/* <div className="card-wrapper" style={{ backgroundColor: `${props.bgcol}` }}> */}
        <div className="card">
          <div className="card-image">
            <img src={props.imageUrl} alt="Council-img" />
            <div className="social-icons">
              <ul>
                {props.links.insta && <li><a href={props.links.insta}><FaInstagram /></a>
                </li>}
                {props.links.git && <li><a href={props.links.git}><FaGithub /></a>
                </li>}
                {props.links.linkedIn && <li><a href={props.links.linkedIn}><FaLinkedinIn /></a>
                </li>}
                {props.links.mail && <li><a href={`mailto:${props.links.mail}`}><FaEnvelope /></a>
                </li>}
              </ul>
            </div>
          </div>
          <div className="details">
            <h4>{props.name}</h4>
            <span className="job-title">{props.role}</span>
          </div>
        </div>
    </Col>
  );
}

export default CouncilCard;
