import { useEffect } from "react";
import db from '../../firebase/FirestoreConnection';
import { WorkCardMap } from "..";

const Collabs = ({ currentCollabs, setCurrentCollabs }) => {
  useEffect(() => {
    let collabs = [];
    const fetchCollabs = async () => {
      db.collection("collabDetails").get().then((querySnapshot) => {
        querySnapshot.forEach(collab => {
          let data = collab.data();
          collabs.push(data);
        });
      }).then(() => {
        setCurrentCollabs(collabs);
      })
    }

    fetchCollabs();

    return fetchCollabs;
  }, []);

  return (
    <WorkCardMap infoArray={currentCollabs} />
  );
}

export default Collabs;