import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';

const ContactCarouselSmall = () => {
    return (
        <Container className="d-block d-md-none about-content shadow" data-aos="fade-up" data-aos-duration="1000">
            <Row>
                <Col xs={{ span: 12 }} md={{ span: 8, offset: 2 }} className="px-1 px-md-0">
                    <Card className="contact-carousel contact-form-card">
                        <Card.Header>
                            <Container>
                                <Row>
                                    <h3 className="mx-auto">Hit Us Up! 🤙</h3>
                                </Row>
                            </Container>
                        </Card.Header>
                        <Card.Body className="px-1 py-3">
                            <Container fluid>
                                <Row>
                                    <Carousel
                                        controls={false}
                                        interval={4000}
                                        className="contact-carousel"
                                    >
                                        <Carousel.Item>
                                            <Container>
                                                <Row>
                                                    <h4 className="contact-list-header mx-auto mb-3">Got any Queries? </h4>
                                                    <div className="mx-auto">
                                                        <div className='contact-list'><strong>Sakshi Shinde</strong> (<i>Chairperson</i>):<br />+91 82758 68278 </div>
                                                        <div className='contact-list'><strong>Sakshi Ganjewar</strong> (<i>Public Relations Head</i>):<br />+91 92841 33351</div>
                                                    </div>
                                                </Row>
                                            </Container>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <Row>
                                                <h4 className="contact-list-header mx-auto mb-3">Memberships Info? </h4>
                                                <div className="mx-auto">
                                                    <div className='contact-list'><strong>Harsh Mohile</strong> (<i>Sr. Treasurer</i>):<br />+91 76780 79156</div>
                                                    <div className='contact-list'><strong>Anurag Shirsekar</strong> (<i>Jr. Treasurer</i>):<br />+91 82915 296506</div>
                                                </div>
                                            </Row>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <Row>
                                                <h4 className="contact-list-header mx-auto mb-3">Technical Trouble? </h4>
                                                <div className="mx-auto">
                                                    <div className='contact-list'><strong>Anish Nair</strong> (<i>Sr. Secretary</i>):<br />+91 86574 17380</div>
                                                    <div className='contact-list'><strong>Gaurang Mapuskar</strong> (<i>Co-Chairperson</i>):<br />+91 99697 80696</div>
                                                </div>
                                            </Row>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <Row>
                                                <h4 className="contact-list-header mx-auto mb-3">Anything Else? </h4>
                                                <div className="mx-auto">
                                                    <div className='contact-list'><strong>Amit Murkalmath</strong> (<i>Operations Secretary</i>):<br />+91 86929 22529</div>
                                                    <div className='contact-list'><strong>Aradhya Ingle</strong> (<i>Managing Secretary</i>):<br />+91 94049 65065</div>
                                                </div>
                                            </Row>
                                        </Carousel.Item>
                                    </Carousel>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactCarouselSmall;