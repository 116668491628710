import React from 'react'
import { Col, Row } from 'react-bootstrap';
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import { FaCheck } from "react-icons/fa";
import { RiHomeGearFill } from "react-icons/ri";
import { TiGroup } from "react-icons/ti";
import { IoMdCheckboxOutline } from "react-icons/io";

export const Counter = () => (
  <div>
    <Row className="counter" data-aos="zoom-in" data-aos-duration="2000">
      <Col lg={4} sm={4}>
        <div className="counter-item shadow">
          <RiHomeGearFill size="40"/>
          <CountUp end={35} redraw={true}>
          {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                  <h1><span ref={countUpRef} />+</h1>
              </VisibilitySensor>
          )}
          </CountUp>
          <p>Workshops &amp; Events</p>
        </div>
      </Col>

      <Col lg={4} sm={4}>
        <div className="counter-item shadow"> 
        <TiGroup size="40"/>
        <CountUp end={500} redraw={true}>
          {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                  <h1><span ref={countUpRef} />+</h1>
              </VisibilitySensor>
          )}
          </CountUp>
          <p>Members</p>
          </div>
    
      </Col>

      <Col lg={4} sm={4}>
        <div className="counter-item shadow"> 
        <IoMdCheckboxOutline size="40"/>
        <CountUp end={200} redraw={true}>
          {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                  <h1><span ref={countUpRef} />+</h1>
              </VisibilitySensor>
          )}
          </CountUp>
          <p>Hours of Learning</p>
          </div>
        
      </Col>

    </Row>
    </div>
);
