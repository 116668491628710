import { useEffect } from "react";
import db from '../../firebase/FirestoreConnection';
import { WorkCardMap } from "..";

const Events = ({ currentEvents, setCurrentEvents }) => {
  useEffect(() => {
    let events = [];
    const fetchEvents = async () => {
      db.collection("eventDetails").get().then((querySnapshot) => {
        querySnapshot.forEach(event => {
          let data = event.data();
          events.push(data);
        });
      }).then(() => {
        setCurrentEvents(events);
      })
    }

    fetchEvents();

    return fetchEvents;
  }, []);

  return (
    <WorkCardMap infoArray={currentEvents} />
  );
}

export default Events;