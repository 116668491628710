import { useEffect } from "react";
import db from '../../firebase/FirestoreConnection';
import { WorkCardMap } from "..";

const Workshops = ({ currentWorkshops, setCurrentWorkshops }) => {
  useEffect(() => {
    let workshops = [];
    const fetchWorkshops = async () => {
      db.collection("workshopDetails").get().then((querySnapshot) => {
        querySnapshot.forEach(workshop => {
          let data = workshop.data();
          workshops.push(data);
        });
      }).then(() => {
        setCurrentWorkshops(workshops);
      })
    }

    fetchWorkshops();

    return fetchWorkshops;
  }, []);

  return (
    <WorkCardMap infoArray={currentWorkshops} />
  );
}
 
export default Workshops;