import { Carousel } from "react-bootstrap";
import BE from "../../assets/home/WEBSITE-BE-Council.webp";
// import SE from "../../assets/home/WEBSITE-SE-Council.webp";
import TE from "../../assets/home/WEBSITE-TE-Council-3.webp";

export const OurTeam = () => (
  <div>
    <div className="council-pic-info-sec" style={{ overflowX: "hidden" }} data-aos="fade-up" data-aos-duration="1000">
      <div className="council-pic-info home-content shadow">
        <Carousel className='HomePageCarousel' indicators={false} data-aos="fade-right" data-aos-duration="1800">
            <Carousel.Item>
              <img src={BE} alt="BE Council" />
            </Carousel.Item>
            <Carousel.Item>
              <img src={TE} alt="TE Council" />
            </Carousel.Item>
            {/* <Carousel.Item>
              <img src={SE} alt="SE Council" />
            </Carousel.Item> */}
        </Carousel>
        <div className="council-pic-info-txt" data-aos="fade-left" data-aos-duration="1800">
          <h1>Our Team</h1>
          <p>
            Whether it comes to conducting events and workshops, or guiding our peers to attain the finest of knowledge, we at CSI-VESIT have always been dedicated to strive hard and will continue to do so.
            <br />
            <br />
            Our council is handpicked from a group of intellectual minds, always ready to assist in various technical and non-technical domains!
          </p>
        </div>
      </div>
    </div>
  </div>
);
